import * as React from 'react';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';

import { required } from '@cometjs/core';

import Layout from '../components/Layout';
import AppBar from '../components/AppBar';
import RegionClosedPartnerList from '../components/RegionClosedPartnerList';
import Placeholder from '../components/Placeholder';

import * as styles from './RegionClosedPartnerListPage.css';

type Props = PageProps<GatsbyTypes.RegionClosedPartnerListPageQuery>;

export const query = graphql`
  query RegionClosedPartnerListPage(
    $regionPublicId: String!
  ) {
    marketplaceRegion(
      publicId: {eq : $regionPublicId}
    ) {
      ...RegionClosedPartnerList_region
      name
      publicId
    }
  }
`;

const RegionClosedPartnerListPage: React.FC<Props> = ({
  data: {
    marketplaceRegion,
  },
}) => {
  required(marketplaceRegion);

  return (
    <Layout>
      <div className={styles.root}>
        <AppBar title={'종료된 오픈알림'} />
        <main className={styles.appbox}>
          {marketplaceRegion.closedPreRegistration.length
            ? <RegionClosedPartnerList region={marketplaceRegion} />
            : <Placeholder>
              종료된 오픈알림이 없어요
            </Placeholder>
          }
        </main>
      </div>
    </Layout>
  );
};

export default RegionClosedPartnerListPage;
