import * as React from 'react';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Option } from '@cometjs/core';

import * as styles from './RegionClosedPartnerList_PreRegistrationEntry_Logo.css';

type Props = {
  preRegistration: (
    GatsbyTypes.RegionClosedPartnerList_PreRegistrationEntry_Logo_preRegistrationFragment
  ),
};

export const query = graphql`
  fragment RegionClosedPartnerList_PreRegistrationEntry_Logo_preRegistration on MarketplaceMiniappPreRegistration {
    name
    icon {
      childImageSharp {
        gatsbyImageData(
          width: 64, 
          height: 64, 
          placeholder: NONE,
          formats: [AUTO, AVIF, WEBP]
        )
      }
    }
  }
`;

const RegionClosedPartnerList_PreRegistrationEntry_Logo: React.FC<Props> = ({
  preRegistration: {
    name,
    icon,
  },
}) => {
  const iconImage = Option.map(icon.childImageSharp?.gatsbyImageData, getImage);

  if (!iconImage) {
    // Placeholder 이미지 데이터 준비
    return null;
  }

  return (
    <div className={styles.root}>
      <GatsbyImage
        image={iconImage}
        loading='lazy'
        alt={`${name} 미니앱 로고`}
      />
    </div>
  );
};

export default RegionClosedPartnerList_PreRegistrationEntry_Logo;
