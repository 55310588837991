import * as React from 'react';
import cc from 'classcat';

import * as styles from './IconContainer.css';

type Props = {
  className?: string,
  children?: React.ReactNode,
  size?: 'small' | 'medium',
};

const IconContainer: React.FC<Props> = ({
  className,
  children,
  size = 'medium',
}) => {
  return (
    <div
      className={cc([
        styles.root({ size }),
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default IconContainer;
