import * as React from 'react';
import { navigate } from 'gatsby';
import { ReactComponent as BackwardIcon } from '@karrotmarket/karrot-ui-icon/svg/icon_backward_regular.svg';

import IconContainer from './IconContainer';

import * as styles from './AppBar.css';

type Props = {
  title?: React.ReactNode,
  border?: boolean,
  action3?: React.ReactNode,
};

const AppBar: React.FC<Props> = ({
  title,
  border = false,
  action3,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.appbar({ border })}>
        <a
          href="#"
          className={styles.back}
          onClick={e => {
            e.preventDefault();
            if (window.history.state?.['fromList']) {
              navigate(-1);
            } else {
              window.__KARROT_BRIDGE_INTERFACE__?.close();
            }
          }}
        >
          <IconContainer>
            <BackwardIcon aria-label="돌아가기" />
          </IconContainer>
        </a>
        <div className={styles.title}>
          <div className={styles.innertitle}>
            {title}
          </div>
        </div>
        <div className={styles.action1}>
          <IconContainer />
        </div>
        <div className={styles.action2}>
          <IconContainer />
        </div>
        <div className={styles.action3}>
          <IconContainer>
            {action3}
          </IconContainer>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
