import { graphql } from 'gatsby';
import * as React from 'react';

import TagGroup from './TagGroup';
import Logo from './RegionClosedPartnerList_PreRegistrationEntry_Logo';

import * as styles from './RegionClosedPartnerList_PreRegistrationEntry.css';

type Props = {
  preRegistration: GatsbyTypes.RegionClosedPartnerList_PreRegistrationEntry_preRegistrationFragment,
};

export const fragments = graphql`
  fragment RegionClosedPartnerList_PreRegistrationEntry_preRegistration on MarketplaceMiniappPreRegistration {
    ...RegionClosedPartnerList_PreRegistrationEntry_Logo_preRegistration
    name
    description
    preRegistrationCount
  }
`;
const RegionClosedPartnerList_PreRegistrationEntry: React.FC<Props> = ({
  preRegistration,
  preRegistration: {
    name,
    description,
    preRegistrationCount,
  },
}) => {
  const formatNumber = new Intl.NumberFormat('kr').format;

  return (
    <article className={styles.root}>
      <Logo preRegistration={preRegistration} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            {name}
          </h2>
          <p className={styles.description}>
            {description}
          </p>
        </div>
        <TagGroup
          tags={[
            { text: '오픈알림 신청 종료' },
            { text: `${formatNumber(preRegistrationCount)}명 신청` },
          ]}
        />
      </div>
    </article>
  );
};

export default RegionClosedPartnerList_PreRegistrationEntry;
