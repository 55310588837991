import * as React from 'react';
import { graphql, Link } from 'gatsby';

import PreRegistrationEntry from './RegionClosedPartnerList_PreRegistrationEntry';

import * as styles from './RegionClosedPartnerList.css';

type Props = {
  region: GatsbyTypes.RegionClosedPartnerList_regionFragment,
};

export const fragments = graphql`
  fragment RegionClosedPartnerList_region on MarketplaceRegion {
    closedPreRegistration {
      miniId
      slug
      ...RegionClosedPartnerList_PreRegistrationEntry_preRegistration
    }
  }
`;

const RegionClosedPartnerList: React.FC<Props> = ({
  region: {
    closedPreRegistration: entries,
  },
}) => {
  return (
    <ul className={styles.root}>
      {entries.map(entry => (
        <li
          key={entry.miniId}
          className={styles.item}
        >
          <Link
            className={styles.link}
            to={`/kr/partner-pre/${entry.slug}/`}
            state={{ fromList: true }}
          >
            <PreRegistrationEntry
              preRegistration={entry}
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default RegionClosedPartnerList;
